@import './_master';

h1 {
  @extend %h1;
}

h2 {
  @extend %h2;
}

h3 {
  @extend %h3;
}

h4 {
  @extend %h4;
}

h5 {
  @extend %h5;
}

h6 {
  @extend %h6;
}

p {
  @extend %p;
}

li {
  @extend %li;
}

// html {
//   font-size: 16px;
//   scroll-behavior: smooth;

//   @include responsive(tablet) {
//     font-size: 14px;
//   }

//   @include responsive(mobile) {
//     font-size: 10px;
//   }
// }

input {
  border: none;
}

input:focus-visible,
select:focus-visible {
  outline: unset;
}

body::-webkit-scrollbar-track {
  //   display: none; // temp hide scrollbar
  background-color: black;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar {
  //   display: none; // temp hide scrollbar
  width: 10px;
}

body::-webkit-scrollbar-thumb {
  //   display: none; // temp hide scrollbar
  width: 10px;
  border-radius: 10px;
  background-position: center;
  background-size: 100% 100%;
  background-size: 100px 100px;
  background-color: blue;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body,
html {
  margin: 0;
  padding: 0;
  overflow-x: clip;
  background-color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
