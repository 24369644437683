$spaceCadet: #2f3450;
$solarBlue: #344e72;
$green: #3ad96b;
$lightGreen: #d3fae0;
$superLightGreen: #5ab96320;
$cultured: #fafafa;
$offWhite: #f5f5f5;
$sunYellow: #ecf178;
$sunTan: #f5d58e;
$sunOrange: #ef7b51;
$sunRed: #e83e2f;
$lightGray: #cccccc;
$discreteGray: #4a5c5c50;
$errorRed: #fd6969;

$greenShadow: 0 0 0 0.15em #3ac97b4d;
$grayShadow: 0 4px 20px 0 #00000040;

$grayBorder: 2px solid #e0e0e0;
$orangeBorder: 2px solid $sunOrange;

$sunGradient: linear-gradient(
  315deg,
  #fcfcfc00 30%,
  #f5df8e26 45%,
  #f3e18640 69%,
  #ffce3940 91%,
  #fcb10acc 100%
);

$sunGradientLight: linear-gradient(
  315deg,
  #fcfcfc00 40%,
  #f5df8e26 60%,
  #f3e18630 75%,
  #ffce3940 95%,
  #fcb10aa0 100%
);

$sunGradientRightLight: linear-gradient(
  45deg,
  #fcfcfc00 40%,
  #f5df8e26 60%,
  #f3e18630 75%,
  #ffce3940 95%,
  #fcb10aa0 100%
);
