@import "../../Styles/_master";

.home {
  position: relative;
  height: 100vh;
  overflow: hidden;

  .home-video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 1;
    object-fit: cover;
  }

  .home-content {
    justify-content: flex-start;
    position: relative;
    min-height: 100vh;
    padding-top: 2rem;
    padding-bottom: 6rem;
    box-sizing: border-box;
    box-shadow: inset 3000px 0 0 #00000030;
    text-align: center;
    z-index: 2;
    @extend %flex-column-center-full;

    .home-logo {
      width: 500px;
      max-width: 90%;
      margin-bottom: 8rem;
    }

    .home-h4 {
      width: 500px;
      max-width: 90%;
      margin-bottom: 3rem;
    }
  }
}
