@import "../../Styles/master";

.signup {
  align-items: center;
  max-width: 95%;
  margin-top: 4rem;
  @extend %flex-center-full;

  &.signup-submitted {
    .signup-input {
      width: 0px;
      margin-right: 0rem;
      padding: 0rem 0rem;
    }
    .signup-submit {
      width: 100%;
      border-radius: 0px;
    }
  }

  .signup-input {
    width: 250px;
    margin-right: 0.5rem;
    padding: 1rem 2rem;
    border-radius: 2px;
    overflow: hidden;
    color: black;
    @extend %h6;
  }

  .signup-submit {
    width: 180px;
    padding: 1rem 2rem;
    border-radius: 2px;
    background-color: $sunRed;
    @extend %transition-standard;
    @extend %flex-center;

    &.signup-submit-submitted,
    &.signup-submit-invalid {
      cursor: not-allowed;
    }

    &.signup-submit-invalid {
      box-shadow: inset 300px 0 0 #00000050;
    }

    &.signup-submit-valid {
      cursor: pointer;
    }

    h4 {
      margin: 0;
    }
  }
}
