@import "./Colors.scss";

$media: screen;
$desktop: 1500px;
$desktopSmall: 1000px;
$tablet: 750px;
$mobile: 500px;

@mixin responsive($breakpoint) {
  @if $breakpoint == mobile {
    @media #{$media} and (max-width: $mobile) {
      @content;
    }
  } @else if $breakpoint == tablet {
    @media #{$media} and (max-width: $tablet) {
      @content;
    }
  } @else if $breakpoint == desktopSmall {
    @media #{$media} and (max-width: $desktopSmall) {
      @content;
    }
  } @else if $breakpoint == desktop {
    @media #{$media} and (min-width: $tablet) {
      @content;
    }
  } @else if $breakpoint == widescreen {
    @media #{$media} and (min-width: $desktop) {
      @content;
    }
  }
}

%h1 {
  margin: 0;
  margin-bottom: 3rem;
  color: white;
  font-family: "Poppins";
  font-size: 4rem;
  font-weight: 600;
  line-height: 5.5rem;
}

%h2 {
  margin: 0;
  padding-bottom: 2rem;
  color: white;
  font-family: "Poppins";
  font-size: 3.5rem;
  font-weight: 600;
  line-height: 4rem;
}

%h3 {
  margin: 0;
  padding-bottom: 2rem;
  color: white;
  font-family: "Poppins";
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 3rem;
}

%h4 {
  margin: 0;
  color: white;
  font-family: "Poppins";
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 2.25rem;
}

%h5 {
  margin: 0;
  color: white;
  font-family: "Poppins";
  font-size: 1.375rem;
  font-weight: 400;
  line-height: 1.875rem;
}

%h6 {
  margin: 0;
  color: white;
  font-family: "Poppins";
  font-size: 1.125rem;
  font-weight: 400;
}

%p {
  margin: 0;
  padding: 1rem 0;
  color: white;
  font-family: "poppins";
  font-size: 1rem;
  font-weight: 400;
}

%li {
  margin: 0;
  padding-bottom: 1rem;
  color: white;
  font-family: "poppins";
  font-size: 1rem;
  font-weight: 400;
}

@mixin outline($color, $size, $blur) {
  text-shadow: -$size (-$size) $blur $color, $size (-$size) $blur $color,
    -$size $size $blur $color, $size $size $blur $color;
}

@mixin double-outline($color, $size, $blur, $size2, $blur2) {
  text-shadow: -$size (-$size) $blur $color, $size (-$size) $blur $color,
    -$size $size $blur $color, $size $size $blur $color,
    -$size2 (-$size2) $blur2 $color, $size2 (-$size2) $blur2 $color,
    -$size2 $size2 $blur2 $color, $size2 $size2 $blur2 $color;
}

%flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

%inline-center {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

%full-width {
  flex-basis: 100%;
  height: 100%;
  width: 100%;
}

%flex-center-full {
  @extend %full-width;
  @extend %flex-center;
}

%flex-column-center-full {
  flex-direction: column;
  @extend %full-width;
  @extend %flex-center;
}

%edge-padding {
  padding: 3rem;

  @include responsive(tablet) {
    padding: 2rem;
  }

  @include responsive(mobile) {
    padding: 1rem;
  }
}

%edge-paddingWide {
  padding: 3rem 3rem;

  @include responsive(widescreen) {
    padding: 3rem 8rem;
  }

  @include responsive(tablet) {
    padding: 2rem 3rem;
  }

  @include responsive(mobile) {
    padding: 1rem 2rem;
  }
}

%transition-standard {
  transition: 0.3s linear all;
}

%horizontal-padding {
  padding-left: 3rem;
  padding-right: 3rem;

  @include responsive(tablet) {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @include responsive(mobile) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
